import axios from 'axios'

export default {

    getBasket(basketPublicId) {

        var path = '/api/customer/basket/' + basketPublicId

        return axios.get(path)
    },
    updateCondition(basketPublicId, conditions) {

        var path = '/api/customer/basket/' + basketPublicId

        return axios.patch(path, conditions)
    },
    getMaxBestOffer(basketPublicId) {

        let path = '/api/customer/basket/' + basketPublicId + '/maxBestOffer';  

        return axios.get(path)
    },
    validateOffer(basketPublicId) {

        var path = '/api/customer/basket/' + basketPublicId + '/validateOffer'

        return axios.patch(path)
    },
    updateStep(basketPublicId, step) {

        var path = '/api/customer/basket/' + basketPublicId + '/step'
        var data = {step: step}

        return axios.patch(path, data)
    },
    create(uid, pid, mid, oid, desc, img, date, price, source = '') {

        let path = '/api/customer/basket'
        let data = {
            uid: uid,
            pid: pid,
            mid: mid,
            oid: oid,
            desc: desc,
            img: img,
            date: date,
            price: price,
            sourceSite: source
        }

        return axios.post(path, data)
    },
    createFromSearchForm(mid, uid, pid, oid, sid, img, source = '', merchantOrderId = '') {

        let path = '/api/customer/searchFormBasket'
        let data = {
            mid: mid,
            uid: uid,
            pid: pid,
            oid: oid,
            img: img,
            sid: sid,
            sourceSite: source,
            moid: merchantOrderId
        }

        return axios.post(path, data)
    }
}

